<template>
  <ManageDeal dialog-name="Deals" />
</template>
<script>
import ManageDeal from '@/views/app/manage/Deal'

export default {
  name: 'DealWrapper',
  components: {
    ManageDeal
  }
}
</script>
